<template>
  <div class="way">
    <div class="title">
      <h4>请选择支付方式</h4>
      <div>
        <p>
          在线待付金额：<span>{{ totalAmount }}</span
          >&nbsp;元
        </p>
        <!-- <p>
          <span>{{ Time }}，</span>请您尽快完成付款，超时订单将自动取消
        </p> -->
      </div>
    </div>
    <div class="payment-box">
      <div
        class="payment"
        v-for="(list, index) in pay"
        :key="index"
        @click="goCashier"
      >
        <img :src="list.img" alt="" />
        <div class="cont">
          <h3>{{ list.name }}</h3>
          <p>{{ list.cont }}</p>
        </div>

        <!-- <div class="selected" v-show="list.isSelect">
          <p>
            支付：<span>{{ totalAmount }}</span
            >&nbsp;元
          </p>
          <img src="@/assets/images/209.png" alt="" />
        </div> -->
      </div>
    </div>
    <!-- <button @click="goCashier">确认提交支付</button> -->
  </div>
</template>

<script>
import { decimals, countDown } from "@/utils/validate";
export default {
  name: "ModeOfPayment",

  data() {
    return {
      pay: [
        {
          name: "微信支付",
          cont: "使用微信扫描二维码即可付款",
          img: require("@/assets/images/vx.png"),
          // isSelect: true,
        },
        {
          name: "支付宝支付",
          cont: "使用手机支付宝扫描二维码即可付款",
          img: require("@/assets/images/zfb.png"),
          // isSelect: false,
        },
        // {
        //   name: "广州银行",
        //   cont: "支持广州银行支付",
        //   img: require("@/assets/images/gzyh.png"),
        //   isSelect: false,
        // },
        // {
        //   name: "通联支付",
        //   cont: "支持通联支付",
        //   img: require("@/assets/images/tl.png"),
        //   isSelect: false,
        // },
      ],
      // orderInformation: "",
      totalAmount: 0,
      // Time: "",
    };
  },
  created() {
    // this.orderInformation = JSON.parse(sessionStorage.getItem("order"));
    this.totalAmount = decimals(Number(sessionStorage.getItem("totalAmount")));
  },
  beforeDestroy() {
    // clearInterval(this.t);
  },
  methods: {
    // selected(i) {
    //   for (let list of this.pay) {
    //     list.isSelect = false;
    //   }
    //   this.pay[i].isSelect = true;
    // },
    goCashier() {
      // console.log(this.orderInformation);
      //   const data = await this.$API.personalUser.TransactionOrder({
      //     object: this.orderInformation,
      //   });

      if (JSON.parse(sessionStorage.getItem("goods"))) {
        // this.formTheOrder();
        // this.deleteGoods();
        sessionStorage.setItem("Time", new Date().getTime());
        this.$router.push({
          path: `Cashier?orderNum=${this.$route.query.orderNum}`,
        });
      } else {
        this.$router.push({
          path: `Cashier?orderNum=${this.$route.query.orderNum}`,
        });
      }
    },
    // CountDown() {
    //   if (this.orderInformation.tradeTime) {
    //     this.Time = countDown(this.orderInformation.tradeTime);
    //     this.t = setInterval(() => {
    //       this.Time = countDown(this.orderInformation.tradeTime);
    //     }, 1000);
    //   } else {
    //     if (sessionStorage.getItem("Time")) {
    //       this.Time = countDown(Number(sessionStorage.getItem("Time")));
    //       this.t = setInterval(() => {
    //         this.Time = countDown(Number(sessionStorage.getItem("Time")));
    //       }, 1000);
    //     } else {
    //       sessionStorage.setItem("Time", new Date().getTime());
    //       this.CountDown();
    //     }
    //   }
    // },
    async formTheOrder() {
      // for (let i = 0; i < this.orderInformation.length; i++) {
      //   const data = await this.$API.personalUser
      //     .TransactionOrder({
      //       object: this.orderInformation[i],
      //     })
      //     .then((res) => {
      //       sessionStorage.setItem(
      //         "orderNum",
      //         JSON.stringify(res.data.data.dealNumber)
      //       );
      //     });
      // }
    },
    //删除商品
    async deleteGoods() {
      //   let id = [];
      //   let goods = JSON.parse(sessionStorage.getItem("goods"));
      //   for (let i = 0; i < goods.length; i++) {
      //     for (const list of goods[i].orderCardListItems) {
      //       id.push(list.id);
      //     }
      //   }
      //   const data = await this.$API.personalUser
      //     .DeleteOrderCart({
      //       object: {
      //         id: id,
      //       },
      //     })
      //     .then((data) => {
      //       sessionStorage.removeItem("goods");
      //     });
    },
  },
};
</script>

<style lang="scss" scoped>
.way {
  width: 1200px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
  .title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    height: 72px;
    padding: 12px 24px;
    font-size: 16px;
    color: #333333;
    h4 {
      font-weight: normal;
      line-height: 48px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      justify-content: center;
      p:nth-child(1) {
        font-size: 14px;
        height: 26px;
        span {
          font-size: 20px;
          color: #fe3132;
          font-weight: 600;
        }
      }
      p:nth-child(2) {
        font-size: 12px;
        height: 14px;
        color: #666666;
        span {
          color: #fe3132;
        }
      }
    }
  }
  .payment-box {
    padding: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .payment {
      width: 568px;
      // flex: 1;
      height: 96px;
      background: rgba(255, 255, 255, 0.39);
      border: 2px solid #e8e8e8;
      opacity: 1;
      padding: 23px;
      display: flex;
      position: relative;
      margin-top: 16px;
      cursor: pointer;
      .cont {
        display: flex;
        margin-left: 16px;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          height: 22px;
          font-size: 17px;
          font-weight: bold;
          color: #333333;
        }
        p {
          height: 20px;
          font-size: 14px;
          color: #999999;
        }
      }
      .selected {
        position: absolute;
        width: 568px;
        height: 96px;
        background: rgba(255, 255, 255, 0);
        border: 2px solid #00aeeb;
        opacity: 1;
        left: -1px;
        top: -1px;
        p {
          height: 24px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -12px;
          span {
            font-size: 18px;
            color: #fe3132;
            font-weight: bold;
          }
        }
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 30px;
        }
      }
    }
    .payment:hover {
      border: 2px solid rgba(0, 196, 92, 1);
    }
    .payment:nth-child(1),
    .payment:nth-child(2) {
      margin-top: 0;
    }
  }
  button {
    width: 180px;
    height: 48px;
    background: #00aeeb;
    box-shadow: 0px 2px 10px rgba(0, 196, 92, 0.4);
    opacity: 1;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    color: #ffffff;
    margin-left: 24px;
    cursor: pointer;
  }
}
</style>